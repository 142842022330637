import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import api from "./api";
const ConcernThankYou = () => {
  const [info, setInfo] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const Navigate = useNavigate();

  useEffect(() => {
    const data = localStorage.getItem("info");
    if (data) setInfo(JSON.parse(data));
  }, []);

  return (
    <div className="epione">
      <div className="epi_container">
        <Header />
        <div className="img_container great_to">
          <div className="epi_page_title">
            Thank you. <br /> One of our staff would reach out to you via e-mail
            or call to assist you. We look forward to seeing you again soon!
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConcernThankYou;
