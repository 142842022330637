import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import api from "./api";
const YourConcern = () => {
  const [info, setInfo] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const Navigate = useNavigate();

  useEffect(() => {
    const data = localStorage.getItem("info");
    if (data) setInfo(JSON.parse(data));
  }, []);

  const stepChange = async () => {
    setSubmitted(true);
    if (info.concern) {
      const resp = await api.post("send-email.php", info);
      if (resp.data.success == true) Navigate("/thank-you");
      else return true;
    }
  };
  return (
    <div className="epione">
      <div className="epi_container">
        <Header url="/" />
        <div className="img_container great_to">
          <div className="epi_page_title">
            Briefly, Please Describe <br /> Your Concern
          </div>
        </div>
        <div className="epi_fields">
          <div className="mb-3">
            <textarea
              placeholder="Describe Your concern"
              onChange={(e) => setInfo({ ...info, concern: e.target.value })}
              className="epi_input"
              style={{
                borderBottomColor: submitted && !info.concern ? "red" : "#ccc",
              }}
            >
              {info.concern ? info.concern : ""}
            </textarea>
          </div>
        </div>
        <div className="epi_btn_sec">
          <div className="epi_btn" onClick={stepChange}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourConcern;
