import React from "react";
import Header from "./Header";

const ThankYou = () => {
  return (
    <div className="epione">
      <div className="epi_container">
        <Header />
        <div className="img_container first_visit">
          <div className="epi_page_title">Thank You for booking your online appointment. We will see you soon.</div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
