import React, { useState, useEffect } from "react";
import Header from "./Header";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import api from "./api";
import { useNavigate, useHistory } from "react-router-dom";

const stripePromise = loadStripe("pk_test_3gSKChq3lRYt7qIGDVEnYfV800gEaFfL3a");

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const Navigate = useNavigate();

  const handleSubmit = (stripe, elements) => async () => {
    const data = localStorage.getItem("info");
    const cardElement = elements.getElement(CardElement);
    console.log(cardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      const params = { payment: paymentMethod, id: JSON.parse(data).order_id, info : JSON.parse(data) };
      const resp = await api.post("save_payment.php", params);
      localStorage.removeItem("info");
      Navigate("/thank-you");
    }
  };

  return (
    <>
      <CardElement />
      <button
        className="epi_btn"
        style={{ border: "none", margin: "25px auto" }}
        onClick={handleSubmit(stripe, elements)}
      >
        Submit
      </button>
    </>
  );
};

const StripePaymentForm = () => {
  const [info, setInfo] = useState([]);
  useEffect(() => {
    const data = localStorage.getItem("info");
    if (data) setInfo(JSON.parse(data));
  }, []);
  return (
    <>
      <div className="epione">
        <div className="epi_container">
          <Header url="/available-slots" />
          <div className="mb-3">
            To secure your chosen slot, we would be needing your payment
            information. You would not get charged unless you're in. You may
            cancel up to 24 hours before your consultation schedule, free of
            charge
          </div>
          <div style={{ maxWidth: 400, width: "100%", marginTop: 25 }}>
            <Elements stripe={stripePromise}>
              <PaymentForm />
            </Elements>
          </div>
        </div>
      </div>
    </>
  );
};
export default StripePaymentForm;
