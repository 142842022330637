import React from "react";
import DrImg from "./../images/dr_img.png";
import { Link } from "react-router-dom";
import Header from "./Header";
const Welcome = () => {
  return (
    <div className="epione">
      <div className="epi_container">
        <Header />
        <div className="img_container">
          <img src={DrImg} alt="Epione" />
        </div>
        <div className="epi_title">
          <h3 className="epi_heading">
            Welcome <br /> Online Consultation <br /> Scheduling
          </h3>
        </div>
        <div className="epi_btn_sec">
          <Link className="epi_btn" to="/about">
            Let's Get Started!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
