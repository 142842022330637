import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Welcome from "./components/Welcome";
import StepTwo from "./components/StepTwo";
import StepThree from "./components/StepThree";
import StepFour from "./components/StepFour";
import StepFive from "./components/StepFive";
import StepSix from "./components/StepSix";
import StepSeven from "./components/StepSeven";
import StepEight from "./components/StepEight";
import StripePaymentForm from "./components/StripePaymentForm";
import ThankYou from "./components/ThankYou";
import YourConcern from "./components/YourConcern";
import ConcernThankYou from "./components/ConcernThankYou";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/about" element={<StepTwo />} />
        <Route path="/contact-details" element={<StepThree />} />
        <Route path="/visit-info" element={<StepFour />} />
        <Route path="/describe-your-concern" element={<YourConcern />} />
        <Route path="/consult-prefer" element={<StepFive />} />
        <Route path="/select-date" element={<StepSix />} />
        <Route path="/available-slots" element={<StepSeven />} />
        {/* <Route path="/card-details" element={<StepEight />} /> */}
        <Route path="/card-details" element={<StripePaymentForm />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/thankyou" element={<ConcernThankYou />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
