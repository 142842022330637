import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const StepThree = () => {
  const [info, setInfo] = useState({ phone: "", email: "" });
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const data = localStorage.getItem("info");
    if (data) setInfo(JSON.parse(data));
    else setInfo({ phone: "", email: "" });
  }, []);

  const stepChange = () => {
    setSubmitted(true);
    if (info.phone && info.email) {
      localStorage.setItem("info", JSON.stringify(info));
      navigate("/visit-info");
    }
  };

  return (
    <div className="epione">
      <div className="epi_container">
        <Header url="/about" />
        <div className="img_container great_to">
          <div className="epi_page_title">
            Please fill in your phone number <br /> and email id
          </div>
        </div>
        <div className="epi_fields">
          <div className="mb-3">
            <PhoneInput
              className="custom-phone-input"
              placeholder="Phone (XXX) XXX XXXX"
              defaultCountry="US"
              value={info.phone}
              onChange={(phone) => setInfo({ ...info, phone })}
              style={{
                borderBottomColor: submitted && !info.phone ? "red" : "#ccc",
              }}
            />
          </div>
          <div className="mb-3">
            <input
              type="email"
              placeholder="E-mail"
              className="epi_input"
              onChange={(e) => setInfo({ ...info, email: e.target.value })}
              value={info.email}
              style={{
                borderBottomColor: submitted && !info.email ? "red" : "#ccc",
              }}
            />
          </div>
        </div>
        <div className="epi_btn_sec">
          <div className="epi_btn" onClick={stepChange}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepThree;
