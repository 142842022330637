import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
const StepFour = () => {
  const [info, setInfo] = useState([]);
  const Navigate = useNavigate();

  useEffect(() => {
    const data = localStorage.getItem("info");
    if (data) setInfo(JSON.parse(data));
  }, []);

  const setpChange = (value) => {
    info.first_visit = value;
    setInfo({ ...info });
    localStorage.setItem("info", JSON.stringify(info));
    if (value == "Yes") Navigate("/consult-prefer");
    else Navigate("/describe-your-concern");
  };
  return (
    <div className="epione">
      <div className="epi_container">
        <Header url="/contact-details" />
        <div className="img_container first_visit">
          <div className="epi_page_title">
            Would this be your first visit
            <br />
            within the last 2 years?
          </div>
        </div>
        <div className="epi_btn_sec yes_no">
          <div
            className={
              info.first_visit === "Yes"
                ? "epi_btn mb-3 epi_btn_active"
                : "epi_btn mb-3"
            }
            onClick={() => setpChange("Yes")}
          >
            Yes
          </div>
          <div
            className={
              info.first_visit === "No"
                ? "epi_btn mb-3 epi_btn_active"
                : "epi_btn mb-3"
            }
            onClick={() => setpChange("No")}
          >
            No
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFour;
